<template>
  <b-modal
    modal-class="small-text"
    ref="EditingHistoryModal"
    header-bg-variant="info"
    size="fluid"
    title="ข้อมูลการแก้ไขการรักษาย้อนหลัง"
    hide-footer
  >
    <b-row>
      <b-col>
        <b-card no-body class="overflow-auto" v-if="treatments[0]">
          <div>
            <h6 class="p-2 mb-0">
              {{ formatDate(treatments[0].creationDt) }}
            </h6>
          </div>
          <b-list-group cl5ass="treatment-list-group" flush>
            <b-list-group-item
              v-for="treatment in treatments"
              :key="treatment.treatId"
              href="#"
              class="flex-column align-items-start p-2 light-gray-bg"
              @click="handleListClick(treatment)"
            >
              <div class="d-flex w-100 justify-content-between">
                <p
                  v-if="isDrawNote(treatment.id)"
                  v-b-tooltip.hover
                  title="มีการบันทึกด้วยการเขียน"
                  class="text-muted m-0 ml-2"
                >
                  <i class="fas fa-pencil-alt"></i>
                </p>
              </div>
              <b-list-group flush>
                <b-list-group-item
                  v-for="item in treatment.items"
                  :key="item.id"
                  class="p-2 border-secondary"
                  :active="isSelected(treatment.id)"
                >
                  <div class="py-1">
                    <div class="d-flex w-100 justify-content-between">
                      <p class="font-weight-bold mb-1">{{ item.treatment }}</p>
                      <p class="mb-0">{{ item.toothNumberSurfaces }}</p>
                    </div>

                    <p v-if="item.dxName" class="mb-1">Dx: {{ item.dxName }}</p>
                    <p
                      v-if="item.note"
                      class="mb-1"
                      style="line-height: 1rem; white-space: pre-line"
                    >
                      {{ item.note }}
                    </p>
                    <hr class="mt-0 mb-1 mx-2" />
                    <div class="d-flex w-100 justify-content-between">
                      <small class="mb-0">
                        {{ item.quantity ? `จำนวน:${item.quantity} /` : ""
                        }}{{ item.price ? ` ราคา ${item.price} บาท` : "" }}
                      </small>
                      <small class="mb-0">
                        {{ item.doctor ? `แพทย์: ${item.doctor}` : "" }}
                      </small>
                      <!-- <small>{{ item }}</small> -->
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
              <!-- {{ treatment }} -->
              <div class="text-right">
                <span
                  v-if="isDiff(treatment)"
                  class="font-weight-light text-danger small"
                >
                  แก้ไขโดย:
                  {{
                    getUserListIncludeDeletedBy(treatment.modifyUid, "uid")
                      .fullName
                  }}
                  {{ formatDateTime(treatment.modifyDt) }}
                </span>
                <span
                  v-else-if="
                    getUserListIncludeDeletedBy(treatment.creationUid, 'uid')
                  "
                  class="font-weight-light small"
                >
                  บันทึกโดย:
                  {{
                    getUserListIncludeDeletedBy(treatment.creationUid, "uid")
                      .fullName
                  }}
                  {{ formatDateTime(treatment.creationDt) }}
                </span>
              </div>
            </b-list-group-item>

            <!-- ไม่มีประวัติ -->
            <b-list-group-item
              v-if="treatments.length === 0"
              class="flex-column align-items-start"
            >
              <div class="d-flex w-100 justify-content-between">
                <p class="mb-0 text-muted">ไม่พบประวัติการรักษา</p>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col>
        <DisplayDrawNote
          ref="displayDrawNote"
          :selectedItem="selectedTreatment"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>
  
<script>
import DisplayDrawNote from "@/components/modal/ProfileCustomer/DisplayDrawNote";

import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import numeral from "numeral";

export default {
  name: "EditHistoryModal",
  components: { DisplayDrawNote },

  data() {
    return {
      treatmentId: null,
      treatments: [],
      selectedTreatment: null,
    };
  },

  computed: {
    ...mapGetters({
      getTreatDiagBy: "moduleAppointment/getTreatDiagBy",

      getUserListIncludeDeletedBy: "moduleUser/getUserListIncludeDeletedBy",
    }),
  },

  methods: {
    ...mapActions({}),
    show(treatmentId) {
      this.$refs.EditingHistoryModal.show();
      this.treatmentId = treatmentId;
      this.$store
        .dispatch("moduleTreatment/fetchAuditTreamentById", {
          id: this.treatmentId,
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        })
        .then(res => {
          this.treatments = res.data
            .sort((a, b) => b.id - a.id)
            .map(i => {
              return { ...i, items: i.items.map(j => this.formatItem(j)) };
            });
        });
    },
    handleListClick(treatment) {
      this.selectedTreatment = treatment;
      if (treatment.drawNote)
        this.$refs.displayDrawNote.assignDrawNote(treatment.drawNote);

      // if (this.apptIdSelected === this.selectedItem.appointmentId) return;
      // this.drawNote = this.getTreatmentHistoryList.find(
      //   (i) => i.appointmentId === this.apptIdSelected
      // )?.drawNote;
      this.$refs.displayDrawNote.resetDrawScale();
    },
    isDrawNote(id) {
      let arrId = this.treatments
        .filter(i => i.drawNote?.lines?.length > 0)
        .map(i => i.id);
      return arrId.includes(id);
    },
    isSelected(id) {
      return id === this.selectedTreatment?.id;
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("llll");
      } else {
        return "";
      }
    },
    formatTime(date) {
      return moment(date).format("HH:mm");
    },
    formatDateTime(date) {
      return moment(date).locale("th").format("L LT");
    },
    isDiff(treatment) {
      if (
        this.getUserListIncludeDeletedBy(treatment.modifyUid, "uid") &&
        this.getUserListIncludeDeletedBy(treatment.creationUid, "uid")
      ) {
        return (
          treatment.modifyUid !== treatment.creationUid ||
          treatment.creationDt !== treatment.modifyDt
        );
      }
    },
    formatItem(item) {
      //manage tooth surfaces
      // let upperAnt = [
      //   "11",
      //   "12",
      //   "13",
      //   "21",
      //   "22",
      //   "23",
      //   "51",
      //   "52",
      //   "53",
      //   "61",
      //   "62",
      //   "63",
      // ];
      // let lowerAnt = [
      //   "31",
      //   "32",
      //   "33",
      //   "41",
      //   "42",
      //   "43",
      //   "71",
      //   "72",
      //   "73",
      //   "81",
      //   "82",
      //   "83",
      // ];
      // let upperPost = [
      //   "14",
      //   "15",
      //   "16",
      //   "17",
      //   "18",
      //   "24",
      //   "25",
      //   "26",
      //   "27",
      //   "28",
      //   "54",
      //   "55",
      //   "64",
      //   "65",
      // ];
      // let lowerPost = [
      //   "34",
      //   "35",
      //   "36",
      //   "37",
      //   "38",
      //   "44",
      //   "45",
      //   "46",
      //   "47",
      //   "48",
      //   "74",
      //   "75",
      //   "84",
      //   "85",
      // ];
      // let surfaces;
      // if (0 <= upperAnt.indexOf(item.toothItems)) {
      let surfaces = [
        { id: 5, label: "O", order: 1 },
        { id: 3, label: "M", order: 2 },
        { id: 4, label: "D", order: 3 },
        { id: 1, label: "B", order: 4 },
        { id: 2, label: "Li", order: 5 },
      ];
      // } else if (0 <= lowerAnt.indexOf(item.toothItems)) {
      //   surfaces = [
      //     { id: 3, label: "M" },
      //     { id: 4, label: "D" },
      //     { id: 5, label: "I" },
      //     { id: 1, label: "La" },
      //     { id: 2, label: "Li" },
      //   ];
      // } else if (0 <= upperPost.indexOf(item.toothItems)) {
      //   surfaces = [
      //     { id: 5, label: "O" },
      //     { id: 3, label: "M" },
      //     { id: 4, label: "D" },
      //     { id: 1, label: "B" },
      //     { id: 2, label: "P" },
      //   ];
      // } else if (0 <= lowerPost.indexOf(item.toothItems)) {
      //   surfaces = [
      //     { id: 5, label: "O" },
      //     { id: 3, label: "M" },
      //     { id: 4, label: "D" },
      //     { id: 1, label: "B" },
      //     { id: 2, label: "Li" },
      //   ];
      // } else {
      //   surfaces = [];
      // }
      let surfacesTx =
        item.surfaceItems
          ?.map(i => surfaces.find(j => j.id === i))
          .sort((a, b) => a.order - b.order)
          .map(i => i.label)
          .join("") || "";

      let treatmentTypeName = {
        1: "การรักษา",
        2: "แพ็กเกจจัดฟัน",
        3: "ยา และอุปกรณ์",
      };
      let event = {
        id: item.id,
        actionUid: item.actionUid,
        appointmentId: item.appointmentId,
        treatId: item.treatId,
        creationUid: item.creationUid,
        creationDt: item.creationDt,
        modifyUid: item.modifyUid,
        modifyDt: item.modifyDt,
        doctor:
          this.getUserListIncludeDeletedBy(item.actionUid, "uid")?.fullName ||
          "",
        toothNo: item.toothItems,
        // toothNumberSurfaces: `${item.toothItems} ${surfacesTx}`,
        toothNumberSurfaces: (item.toothItems || "") + (surfacesTx || ""),
        treatment: item.name,
        price: numeral(item.price).format("0,0"),
        note: item.note,
        txTypeId: item.typeId,
        txTypeName: treatmentTypeName[item.typeId],
        dxName: this.getTreatDiagBy(item.diagItems, "id").nameEn,
        quantity: item.quantity,
      };
      return event;
    },
  },
};
</script>
  
<style scope>
.light-gray-bg {
  background-color: rgb(228, 228, 228) !important;
}
</style>
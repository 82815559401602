<template>
  <b-modal
    modal-class="component-modal-profile-patient"
    id="ProfileCustomerModal"
    ref="ProfileCustomerModal"
    size="fluid"
    title="ประวัติคนไข้"
    scrollable
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    :hide-footer="hideFooter"
    @hide="enableBodyScroll"
    @hidden="defaultValue"
    static
  >
    <button class="close top-right" @click="hideModal">×</button>
    <div class>
      <b-tabs v-model="tabIndex">
        <b-tab lazy class="pt-0" title="ประวัติการรักษา">
          <TreatmentHx
            ref="treatmentHx"
            :patient="patient"
            :isItemTableBusy="isItemTableBusy"
            @openCustomerModal="editCustomer"
          />
        </b-tab>
        <b-tab lazy class="pt-0" title="ประวัติการนัด">
          <AppointmentHx
            ref="appointmentHx"
            :patient="patient"
            @openCustomerModal="editCustomer"
          />
        </b-tab>
        <b-tab class="pt-0" lazy title="ประวัติการชำระเงิน">
          <PatientPaymentHx
            :patient="patient"
            @openCustomerModal="editCustomer"
          />
        </b-tab>
        <b-tab v-if="hasSubscribed" class="pt-0" title="รูป">
          <PhotoGallery 
            :patient="patient" 
            mode="photo"
            @openCustomerModal="editCustomer" 
          />
        </b-tab>
        <b-tab v-if="hasSubscribed" lazy class="pt-0" title="เอกสาร">
          <PhotoGallery 
            :patient="patient" 
            mode="document"
            @openCustomerModal="editCustomer" 
          />
        </b-tab>
      </b-tabs>
    </div>
    <template v-slot:modal-footer>
      <b-dropdown dropup class="mr-auto" text="เอกสารอื่นๆ">
        <b-dropdown-item @click="openMedicalCertModal"
          >ใบรับรองแพทย์</b-dropdown-item
        >
        <b-dropdown-item @click="$refs.treatmentPlanModal.show(patient)"
          >แผนการรักษา</b-dropdown-item
        >
        <b-dropdown-item @click="$refs.cariesRiskModal.show(patientId)"
          >ความเสี่ยงฟันผุ</b-dropdown-item
        >
      </b-dropdown>

      <span class="text-right" v-if="!hideActionButtons">
        <b-button v-if="isOverdue" variant="warning" @click="checkAptOvedue"
          >ชำระค้างจ่าย</b-button
        >
        <b-button
          v-if="
            !(
              inClinic ||
              !isTodayApt ||
              !isOpeningHour ||
              onDoctorsTreatingQueue
            )
          "
          class="ml-2"
          @click="checkIn"
          >ส่งตรวจทันที</b-button
        >
        <b-button
          v-if="
            !(
              inClinic ||
              !isTodayApt ||
              !isOpeningHour ||
              onDoctorsTreatingQueue
            )
          "
          class="ml-2"
          variant
          @click="payment"
          >บันทึกการรักษา</b-button
        >
        <b-button
          v-if="!onDoctorsTreatingQueue"
          class="ml-2"
          variant="primary"
          @click="createEvent"
          >สร้างนัดหมาย</b-button
        >
      </span>
    </template>
    <QuickEventModal ref="quickEventModal" />
    <SelectAptModal ref="selectAptModal" />
    <MedicalCertModal ref="medicalCertModal" />
    <TreatmentPlanModal ref="treatmentPlanModal" />
    <CariesRiskModal ref="cariesRiskModal" />
    <TTDConsultModal ref="ttdConsultModal" :patient="patient" />
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import QuickEventModal from "@/components/modal/QuickEvent";
import SelectAptModal from "@/components/modal/SelectApt";
import MedicalCertModal from "@/components/modal/PrintableDocs/MedicalCert";
import TreatmentPlanModal from "@/components/modal/TreatmentPlan";
import CariesRiskModal from "@/components/modal/CariesRiskAssesment";
import TTDConsultModal from "@/components/modal/TTDConsult";

import PatientPaymentHx from "@/components/modal/ProfileCustomer/PaymentHx";
import TreatmentHx from "@/components/modal/ProfileCustomer/TreatmentHx";
import AppointmentHx from "@/components/modal/ProfileCustomer/AppointmentHx";
import PhotoGallery from "@/components/modal/ProfileCustomer/PhotoGallery";
import Loading from "@/components/Loading";
import Dialog from "@/components/modal/Dialog";

import moment from "moment";

import { eventBus } from "@/main";

import "@/theme/modal/ProfileCustomer.scss";

export default {
  name: "ProfileCustomerModal",
  components: {
    QuickEventModal,
    SelectAptModal,
    MedicalCertModal,
    TreatmentPlanModal,
    CariesRiskModal,
    TTDConsultModal,

    PatientPaymentHx,
    TreatmentHx,
    AppointmentHx,
    PhotoGallery,
    Loading,
    Dialog,
  },

  data() {
    return {
      isLoading: false,
      appointment: {},
      patient: {},
      patientId: null,
      hideAction: false,
      dateRange: [],

      //Treatment history
      isItemTableBusy: false,

      patientOrthoPackage: {},

      //Matching appointment
      matchedApt: [],
      mode: "",

      overdueList: [],
      hideFooter: false,

      tabIndex: 0,
      scrollY: 0,
    };
  },
  computed: {
    ...mapGetters({
      isFileStorageExpired: "moduleUser/isFileStorageExpired",
      hasSubscribed: "moduleUser/hasSubscribed",
      viewAvatar: "moduleContent/viewAvatar",
      getUserInfo: "moduleUser/getUserInfo",
      getBranchInfo: "moduleUser/getBranchInfo",
      isDoctor: "moduleUser/isDoctor",
      patientInfo: "modulePatient/getPatientInfo",
      getAppointmentHistoryList: "moduleAppointment/getAppointmentHistoryList",
      getAptHxTableList: "moduleAppointment/getAptHxTableList",
      getUserListIncludeDeletedBy: "moduleUser/getUserListIncludeDeletedBy",
      getTreatmentBy: "moduleAppointment/getTreatmentBy",
      getAppointmentStatusList: "moduleMaster/getAppointmentStatusList",
      getAppointmentStatusBy: "moduleMaster/getAppointmentStatusBy",
      getToothBy: "moduleMaster/getToothBy",
      getToothSurfaceBy: "moduleMaster/getToothSurfaceBy",
      isAccessMenu: "moduleUser/getIsAccessMenu",
    }),

    inClinic() {
      if (this.appointment.extendedProps) {
        let statusId = this.appointment.extendedProps.statusId;
        return [5, 6].includes(statusId) ? true : false;
      } else {
        return false;
      }
    },
    isTodayApt() {
      if (this.appointment.start) {
        return moment().isSame(this.appointment.start, "day");
      } else {
        return true;
      }
    },
    isOpeningHour() {
      let convertTime = str => moment(str, "HH:mm");
      return moment().isBetween(
        convertTime(this.getBranchInfo.confOpeningTime || "09:00"),
        convertTime(this.getBranchInfo.confClosingTime || "21:00"),
        "minutes",
        "[)"
      );
    },
    onQueueManageRoute() {
      return this.$route.name === "QueueManage";
    },
    onDoctorsTreatingQueue() {
      return this.$route.name === "DoctorsTreatingQueue";
    },
    hideActionButtons() {
      return (
        this.hideAction ||
        !this.isAccessMenu(["COUNTER", "MANAGER", "HEADCOUNTER"])
      );
    },
    isOverdue() {
      return this.overdueList?.length > 0;
    },
  },
  methods: {
    ...mapActions({
      fetchPatientById: "modulePatient/fetchPatientById",
      addRecentPatient: "modulePatient/addRecentPatient",

      fetchTreatmentHistoryList: "moduleTreatment/fetchTreatmentHistoryList",
      fetchQueueAppointmentList: "moduleAppointment/fetchQueueAppointmentList",
      fetchOverdueTreatmentByPatientId:
        "moduleTreatment/fetchOverdueTreatmentByPatientId",
      fetchPatientOrthoPackage: "moduleTreatment/fetchPatientOrthoPackage",
      fecthMediaFiles: "moduleMediaFile/fecthMediaFiles",
    }),
    async show(appointment, patient, hideAction, mode) {
      this.$bvModal.show("ProfileCustomerModal");

      this.disableBodyScroll();

      this.hideAction = hideAction;
      this.mode = mode;
      this.appointment = Object.assign({}, appointment);
      this.patientId = patient.id;
      let clinicUrl = this.$route.params.clinicUrl;
      let branchUrl = this.$route.params.branchUrl;

      try {
        this.isItemTableBusy = true;
        await this.fetchPatientById({
          clinicUrl,
          branchUrl,
          id: this.patientId,
        }).then(res => {
          this.patient = res.data;
        });
        this.fetchOverdueTreatmentByPatientId({
          patientId: this.patientId,
          clinicUrl,
          branchUrl,
        }).then(res => {
          this.overdueList = res.data;
        });
        Promise.all([
          this.fetchTreatmentHisory(),
          this.handleFetchPtOrthoPkg(),
        ]).then(() => {
          this.isItemTableBusy = false;
        });
        this.isLoading = true;
        if (this.hasSubscribed) {
          this.fecthMediaFiles({
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
            params: { patientId: this.patientId, limit: 100 },
          });
        }

        this.addRecentPatient(this.patient);
      } catch (err) {
        console.error(err);
      } finally {
        await this.$nextTick();
        if (this.mode === "aptHX") this.tabIndex = 1;
        this.isLoading = false;
      }
    },
    editCustomer(patient) {
      this.$emit(
        "openCustomerModal",
        Object.assign({}, patient),
        this.appointment
      );
    },
    createEvent() {
      this.$refs["ProfileCustomerModal"].hide();
      let appointment = Object.assign({}, this.appointment);
      let patient = Object.assign({}, this.patient);
      this.mode === "createAptOnly" //กรณีเรียก popover -> ดูประวัติ -> ปุ่มสร้าางนัดหมาย สามารถสร้างนัดหมายใหม่ได้อย่างเดียว ไม่ได้แก้ไขนัดหมายเดิม
        ? this.$emit("openEventModal", null, patient)
        : this.$emit("openEventModal", appointment, patient);
    },
    async fetchTreatmentHisory() {
      let clinicUrl = this.$route.params.clinicUrl;
      let branchUrl = this.$route.params.branchUrl;
      let event = {
        clinicUrl: clinicUrl,
        branchUrl: branchUrl,
        patientId: this.patientId,
      };
      await this.fetchTreatmentHistoryList(event)
        .then(() => {
          this.$refs.treatmentHx?.select1stIndex();
        })
        .finally(() => {
          // this.isItemTableBusy = false;
        });
    },

    async handleFetchPtOrthoPkg() {
      try {
        await this.fetchPatientOrthoPackage({
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          patientId: this.patientId,
        });
      } catch (err) {
        console.error(err);
      }
    },

    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
    formatTime(date) {
      if (date) {
        return moment(date).locale("th").format("HH:mm");
      } else {
        return "";
      }
    },

    defaultValue() {
      this.appointment = {};
      this.patient = {};
      this.patientId = null;
      this.matchedApt = [];
      this.overdueList = [];
      this.tabIndex = 0;
      eventBus.$emit("setDefaultValueProfileCustomer");
    },

    async matchingTodayApt() {
      this.isLoading = true;
      let todayAppointmentList = [];

      await this.fetchQueueAppointmentList({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        date: moment().format("YYYY-MM-DD"),
        store: false,
      })
        .then(res => {
          todayAppointmentList = res.data;
          this.matchedApt = todayAppointmentList.filter(
            apt =>
              this.patient.id == apt.extendedProps.patient?.id && //เช็คว่ามีนัดในวันนั้นหรือไม่
              ![7].includes(apt.extendedProps.statusId) // เช็คว่าอยู่ใน status ชำระเงินแล้ว หรือไม่
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async checkIn() {
      if (this.appointment.id && this.appointment.extendedProps.statusId != 7) {
        this.$parent.openCheckInModal(null, this.appointment);
      } else {
        if (!this.isOpeningHour) {
          this.$refs.Dialog.showAlertInfo(
            "ไม่สามารถส่งตรวจตอนนี้ได้",
            `อยู่นอกเวลาทำการ (${this.getBranchInfo.confOpeningTime}-${this.getBranchInfo.confClosingTime})`
          );
          return;
        }
        await this.matchingTodayApt();
        if (this.matchedApt.length === 0) {
          let apt =
            this.appointment?.extendedProps?.statusId == 7
              ? null
              : this.appointment;
          this.$parent.openCheckInModal(null, apt, this.patient);
        } else {
          //เช็คว่าอยู๋ในสถานะ รอเข้าตรวจ หรือรอชำระเงิน?
          let inQueueApt = this.matchedApt.filter(apt =>
            [5, 6].includes(apt.extendedProps.statusId)
          );
          if (inQueueApt[0]) {
            let statusId = inQueueApt[0].extendedProps.statusId;
            let statusObj = this.getAppointmentStatusList.find(
              status => status.code == statusId
            );
            this.$refs.Dialog.showAlertInfo(
              "ไม่สามารถส่งตรวจได้",
              `เนื่องจาก คุณ ${
                this.patient.firstNameTh + " " + this.patient.lastNameTh
              } อยู่ในสถานะ "${statusObj.label}"`,
              "warning"
            );
          } else {
            this.openSelectAptModal(this.matchedApt, this.patient);
          }
        }
      }
    },
    async payment() {
      if (this.appointment.id && this.appointment.extendedProps.statusId != 7) {
        this.$parent.openPaymentHistoryModal(this.appointment, this.patient);
      } else {
        await this.matchingTodayApt();
        if (this.matchedApt.length === 0) {
          let apt =
            this.appointment?.extendedProps?.statusId == 7
              ? null
              : this.appointment;
          this.openQuickEventModal(this.patient, apt);
        } else {
          //เช็คว่าอยู๋ในสถานะ รอเข้าตรวจ หรือรอชำระเงิน?
          let inQueueApt = this.matchedApt.filter(apt =>
            [5, 6].includes(apt.extendedProps.statusId)
          );
          if (inQueueApt[0]) {
            let statusId = inQueueApt[0].extendedProps.statusId;
            let statusObj = this.getAppointmentStatusList.find(
              status => status.code == statusId
            );
            this.$refs.Dialog.showAlertInfo(
              "ไม่สามารถบันทึกการรักษาได้",
              `เนื่องจาก คุณ ${
                this.patient.firstNameTh + " " + this.patient.lastNameTh
              } อยู่ในสถานะ "${statusObj.label}"`,
              "warning"
            );
          } else {
            this.openSelectAptModal(this.matchedApt, this.patient, "payment");
          }
        }
      }
    },
    openSelectAptModal(matchedApt, patient, from) {
      this.$refs.selectAptModal.show(matchedApt, patient, from);
    },
    openQuickEventModal(patient, appointment) {
      this.$refs.quickEventModal.show(patient, appointment);
    },
    openMedicalCertModal() {
      this.$refs.medicalCertModal.show(this.patient);
    },
    async checkAptOvedue() {
      await this.matchingTodayApt();
      if (this.matchedApt.length === 0) {
        this.openQuickEventModalOverdue();
      } else {
        this.$refs.Dialog.showAlertConfirm(
          "คนไข้มีนัดหมายในวันนี้แล้ว",
          '"ชำระค้างจ่าย" จะสร้างนัดหมายใหม่ขึ้นมาอัตโนมัติ คุณต้องการสร้างนัดหมายใหม่ หรือไม่'
        ).then(res => {
          if (res.isConfirmed) {
            this.openQuickEventModalOverdue();
          }
        });
      }
    },
    openQuickEventModalOverdue() {
      this.$refs.quickEventModal.show(
        this.patient,
        this.appointment,
        "overdue"
      );
    },
    openTtdConsultModal() {
      this.$refs.ttdConsultModal.show();
    },
    hideModal() {
      this.$refs["ProfileCustomerModal"].hide();
    },
    handleDrawingModalShown(isModalShown) {
      this.hideFooter = isModalShown;
      this.modalScroll(isModalShown);
    },
    modalScroll(isModalShown) {
      let modalBody = document.getElementById(
        "ProfileCustomerModal___BV_modal_body_"
      );
      if (isModalShown) {
        modalBody.classList.add("no-scroll");
      } else {
        modalBody.classList.remove("no-scroll");
      }
    },
    disableBodyScroll() {
      this.scrollY = window.scrollY;
      document.body.style.position = "fixed";
      document.body.style.right = "0px";
      document.body.style.left = "0px";
    },
    enableBodyScroll() {
      document.body.style.right = "unset";
      document.body.style.left = "unset";
      document.body.style.position = "unset";
      document.body.style.top = "unset";
      window.scrollTo(0, parseInt(this.scrollY || "0"));
    },
  },
  mounted() {
    eventBus.$on("updatePatientInfo", () => {
      this.patient = this.patientInfo;
    });
    eventBus.$on("isDrawingModalShown", e => {
      this.handleDrawingModalShown(e);
    });
    eventBus.$on("fecthTreatmentHistory", async () => {
      console.log("---fecthTreatmentHistoryOnEventBus---");
      try {
        this.isLoading = true;
        await this.fetchTreatmentHisory();
        this.$refs.treatmentHx.applyDrawNote();
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    });
  },
  beforeDestroy() {
    this.enableBodyScroll();
    eventBus.$off("fecthTreatmentHistory");
  },
};
</script>

<style>
</style>

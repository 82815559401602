var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('div',{staticClass:"col-12 col-lg-2 divider-lg-right divider-bottom divider-lg-bottom-0 mb-3 mb-lg-0"},[_c('PatientProfile',{attrs:{"patient":_vm.patient,"button-name":"ข้อมูลติดต่อ","button-click":_vm.editCustomer}})],1),_c('div',{staticClass:"col-12 col-lg-10"},[_c('b-container',{staticClass:"photo-gallery",attrs:{"fluid":""}},[_c('b-overlay',{attrs:{"show":_vm.isFetching,"rounded":"sm","spinner-variant":"primary"}},[_c('div',{staticClass:"mb-3 p-1 bg-light rounded d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(_vm._s(_vm.selectedFiles.length)+" files selected")]),_c('div',[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","size":"sm","disabled":!_vm.selectedFiles.length},on:{"click":_vm.handleBulkDownload}},[_c('i',{staticClass:"fas fa-download mr-1"}),_vm._v(" Download Selected ")]),_c('b-button',{attrs:{"variant":"danger","size":"sm","disabled":!_vm.selectedFiles.length},on:{"click":_vm.handleBulkDelete}},[_c('i',{staticClass:"fas fa-trash-alt mr-1"}),_vm._v(" Delete Selected ")])],1)]),_vm._l((_vm.currentCategories),function(category){return _c('CategoryCard',{key:category.value,attrs:{"title":category.text,"category":category.value,"items":_vm.filteredList(category.value),"selected-files":_vm.selectedFiles},on:{"upload":_vm.showUploadModal,"select":_vm.toggleFileSelection,"preview":_vm.handlePreview,"download":_vm.handleDownload,"delete":_vm.handleDelete,"sign":_vm.openSignTab,"radiiView":_vm.openRadiiView}})})],2)],1)],1),_c('Lightbox',{attrs:{"index":_vm.lightBoxIndex,"items":_vm.lightBoxList,"toolbar":[
      'counter',
      'zoom',
      'slide',
      'rotate',
      'gallery',
      'fullscreen',
      'close' ]},on:{"close":_vm.resetLightbox}}),_c('UploadFilesModal',{ref:"uploadFiles",attrs:{"patientId":_vm.patient.id},on:{"uploadedFiles":_vm.pollForUpdates,"uploadedTemplateFiles":_vm.pollForUpdatesTemplate}}),(_vm.isLoading)?_c('Loading'):_vm._e(),_c('Dialog',{ref:"Dialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
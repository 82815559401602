import axios from 'axios';
import moment from 'moment';

const getFileExtension = fileType => {
  const extensionMap = {
    "application/pdf": ".pdf",
    "image/jpeg": ".jpg",
    "image/png": ".png",
    "image/gif": ".gif",
    "image/webp": ".webp",
  };
  return extensionMap[fileType] || "";
};

const sanitizeFileName = name => {
  return name.replace(/[^a-zA-Z0-9-_. ]/g, "").trim();
};

export function useFileOperations() {
  const downloadFile = async (item, patientId) => {
    try {
      // Generate filename components
      const date = moment(item.creationDt).format("YYYYMMDD");
      const category = item.category || "file";
      const remark = item.remark ? `-${sanitizeFileName(item.remark)}` : "";
      const extension = getFileExtension(item.fileType);

      // Create full filename
      const fileName = `${date}-${category}-${patientId}${remark}${extension}`;

      // Remove authorization header temporarily
      const originalAuth = axios.defaults.headers.common["Authorization"];
      delete axios.defaults.headers.common["Authorization"];

      try {
        const response = await axios({
          url: item.src,
          method: "GET",
          responseType: "blob",
          withCredentials: false,
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
          },
        });

        // Create download link directly from response.data
        const downloadUrl = window.URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.style.display = "none";
        link.href = downloadUrl;
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        // Cleanup
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(link);
      } finally {
        // Restore authorization header
        if (originalAuth) {
          axios.defaults.headers.common["Authorization"] = originalAuth;
        }
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      throw new Error("Failed to download file. Please try again.");
    }
  };

  const bulkDownload = async (files, patientId) => {
    for (const file of files) {
      await downloadFile(file, patientId);
    }
  };

  return {
    downloadFile,
    bulkDownload
  };
}

<template>
  <b-card body-class="p-2">
    <b-card-title class="mb-1 text-small">
      {{ title }}
      <b-button
        class="ml-2"
        size="sm"
        variant="primary"
        @click="$emit('upload', category)"
      >+</b-button>
    </b-card-title>
    <b-card-text class="mx-2">
      <b-row>
        <b-col
          v-for="item in items"
          :key="item.id"
          class="p-1"
          cols="6"
          sm="4"
          md="3"
          lg="2"
          xl="2"
        >
          <FileItem
            :item="item"
            :category="category"
            :is-selected="selectedFiles.some(f => f.id === item.id)"
            @select="$emit('select', $event)"
            @preview="$emit('preview', $event)"
            @download="$emit('download', $event)"
            @delete="$emit('delete', $event)"
            @sign="$emit('sign', $event)"
            @radiiView="$emit('radiiView', $event)"
          />
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
import FileItem from './FileItem.vue';

export default {
  name: 'CategoryCard',
  components: {
    FileItem
  },
  props: {
    title: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    selectedFiles: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped>
.card-title {
  font-size: 1rem;
  font-weight: 500;
  color: #343a40;
  display: flex;
  align-items: center;
}

.btn-xs {
  padding: 0.125rem 0.25rem;
  font-size: 0.75rem;
}

.card {
  margin-bottom: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.card-body {
  padding: 1rem;
}
</style>

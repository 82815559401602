<template>
  <b-modal
    id="NewUserModal"
    ref="NewUserModal"
    size="md fluid"
    title="สร้างผู้ใช้ใหม่"
    no-close-on-backdrop
    hide-footer
    @hidden="setDefaultValue"
  >
    <b-form-group
      description="อีเมล์ที่ใช้ในการลงชื่อเข้าใช้งาน ของผู้ใช้ใหม่"
      label="กรุณาใส่อีเมลล์ ของผู้ใช้ใหม่"
    >
      <b-input-group class>
        <b-form-input
          :class="{ 'is-invalid': $v.registerEmail.$error }"
          v-model="$v.registerEmail.$model"
          type="email"
          lazy
          trim
          @change="validateEmail"
        ></b-form-input>
        <template #append>
          <b-button variant @click="validateEmail">ตรวจสอบ</b-button>
        </template>
      </b-input-group>
      <template #description v-if="$v.registerEmail.$error">
        <span class="description error">{{ inValidEmail }}</span>
      </template>
    </b-form-group>
    <!-- {{ $v.registerEmail }} -->

    <!-- ลงทะเบียนใหม่ -->
    <div v-if="registerStatus === 1">
      <hr />
      <h5>ลงทะเบียนผู้ใช้ใหม่</h5>
      <b-row>
        <b-col cols="6">
          <b-form-group label="รหัสผ่าน">
            <b-form-input
              :class="{
                'is-invalid': $v.registerForm.password.$error,
              }"
              v-model="$v.registerForm.password.$model"
              type="password"
              trim
              lazy
            ></b-form-input>
            <template #description>
              <div
                class="description error"
                v-if="$v.registerForm.password.$error"
              >
                <span v-if="!$v.registerForm.password.required"
                  >*กรุณาใส่รหัสผ่าน</span
                >
                <span v-else-if="!$v.registerForm.password.minLength"
                  >*รหัสผ่านต้องมีอย่างน้อย 8 ตัวขึ้นไป</span
                >
                <span v-else-if="!$v.registerForm.password.passwordFormat">
                  *รหัสผ่านต้องประกอบไปด้วยตัวเลข (0-9) และตัวอักษรภาษาอังกฤษ
                  (A-Z, a-z) อย่างน้อย 1 ตัว
                </span>
              </div>
            </template>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="ยืนยันรหัสผ่าน">
            <b-form-input
              :class="{
                'is-invalid': $v.registerForm.confirmPassword.$error,
              }"
              v-model="$v.registerForm.confirmPassword.$model"
              type="password"
              trim
              lazy
            ></b-form-input>
            <template #description>
              <div
                class="description error"
                v-if="$v.registerForm.confirmPassword.$error"
              >
                <span v-if="!$v.registerForm.confirmPassword.required"
                  >*กรุณาใส่รหัสผ่าน</span
                >
                <span
                  v-else-if="!$v.registerForm.confirmPassword.sameAsPassword"
                  >*ไม่ตรงกับรหัสผ่าน</span
                >
              </div>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="ชื่อภาษาไทย">
            <b-form-input
              :class="{
                'is-invalid': $v.registerForm.firstNameTh.$error,
              }"
              v-model="$v.registerForm.firstNameTh.$model"
              trim
            ></b-form-input>
            <template #description>
              <div
                class="description error"
                v-if="$v.registerForm.firstNameTh.$error"
              >
                <span v-if="!$v.registerForm.firstNameTh.required"
                  >*กรุณาใส่ชื่อภาษาไทย</span
                >
              </div>
            </template>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="นามสกุลภาษาไทย">
            <b-form-input
              :class="{
                'is-invalid': $v.registerForm.lastNameTh.$error,
              }"
              v-model="$v.registerForm.lastNameTh.$model"
              trim
            ></b-form-input>
            <template #description>
              <div
                class="description error"
                v-if="$v.registerForm.lastNameTh.$error"
              >
                <span v-if="!$v.registerForm.lastNameTh.required"
                  >*กรุณาใส่นามสกุลภาษาไทย</span
                >
              </div>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="ชื่อภาษาอังกฤษ">
            <b-form-input
              :class="{
                'is-invalid': $v.registerForm.firstNameEn.$error,
              }"
              v-model="$v.registerForm.firstNameEn.$model"
              trim
            ></b-form-input>
            <template #description>
              <div
                class="description error"
                v-if="$v.registerForm.firstNameEn.$error"
              >
                <span v-if="!$v.registerForm.firstNameEn.required"
                  >*กรุณาใส่ชื่อภาษาอังกฤษ</span
                >
              </div>
            </template>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="นามสกุลภาษาอังกฤษ">
            <b-form-input
              :class="{
                'is-invalid': $v.registerForm.lastNameEn.$error,
              }"
              v-model="$v.registerForm.lastNameEn.$model"
              trim
            ></b-form-input>
            <template #description>
              <div
                class="description error"
                v-if="$v.registerForm.lastNameEn.$error"
              >
                <span v-if="!$v.registerForm.lastNameEn.required"
                  >*กรุณาใส่นามสกุลภาษาอังกฤษ</span
                >
              </div>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="เบอร์โทรศัพท์มือถือ">
            <b-form-input
              :class="{
                'is-invalid': $v.registerForm.mobile.$error,
              }"
              v-model="$v.registerForm.mobile.$model"
              trim
            ></b-form-input>
            <template #description>
              <div
                class="description error"
                v-if="$v.registerForm.mobile.$error"
              >
                <span v-if="!$v.registerForm.mobile.required"
                  >*กรุณาใส่เบอร์โทรศัพท์</span
                >
                <span v-else-if="!$v.registerForm.mobile.digits"
                  >*เบอร์โทรศัพท์ต้องมีตัวเลข 10 ตัว</span
                >
              </div>
            </template>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group class="normal-weight" label="ประเภทผู้ใช้">
            <b-form-radio
              v-model="$v.registerForm.userTypeId.$model"
              name="user-type"
              :value="1"
              >ผู้ใช้ทั่วไป (เคาน์เตอร์, ผู้จัดการ, ผู้ช่วย)</b-form-radio
            >
            <b-form-radio
              v-model="$v.registerForm.userTypeId.$model"
              name="user-type"
              :value="2"
              >ทันตแพทย์</b-form-radio
            >
            <template #description>
              <div
                class="description error"
                v-if="$v.registerForm.userTypeId.$error"
              >
                <span v-if="!$v.registerForm.userTypeId.required"
                  >*กรุณาเลือกประเภทผู้ใช้</span
                >
              </div>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="isDentist">
        <b-col cols="6">
          <b-form-group label="เลข ท.">
            <b-form-input
              :class="{
                'is-invalid': $v.registerForm.drLicenseNo.$error,
              }"
              v-model="$v.registerForm.drLicenseNo.$model"
              trim
              type="number"
            ></b-form-input>
            <template #description>
              <div
                class="description error"
                v-if="$v.registerForm.drLicenseNo.$error"
              >
                <span>*กรุณาใส่เลข ท. (ใส่เฉพาะตัวเลข)</span>
              </div>
            </template>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="สาขาเฉพาะทาง">
            <multiselect
              :class="{
                'is-invalid': $v.registerForm.drSpecialItems.$error,
              }"
              :options="specialistList"
              :multiple="true"
              track-by="val"
              label="label"
              :close-on-select="false"
              v-model="selectedSpacialist"
            ></multiselect>
            <template #description>
              <div
                class="description error"
                v-if="$v.registerForm.drSpecialItems.$error"
              >
                <span v-if="!$v.registerForm.drSpecialItems.required"
                  >*กรุณาเลือกสาขาเฉพาะทาง</span
                >
              </div>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button class="float-right" variant="primary" @click="handleRegister"
        >ลงทะเบียน</b-button
      >
    </div>

    <!-- ลงทะเบียนแล้ว -->
    <div v-if="registerStatus === 2">
      <hr />
      <h5 class="text-success">
        อีเมลล์ลงทะเบียนแล้ว กรุณาระบุตำแหน่งในคลินิก
      </h5>
      <b-row>
        <b-col>
          <b-form-group label="ตำแหน่ง">
            <multiselect
              :class="{ 'is-invalid': $v.selectedRoles.$error }"
              v-model="$v.selectedRoles.$model"
              multiple
              track-by="code"
              label="label"
              value="val"
              placeholder="กรุณาเลือกตำแหน่ง"
              selectLabel="เลือก"
              selectedLabel="เลือกแล้ว"
              deselect-label="คลิกเพื่อลบ"
              :options="rolesList"
              :searchable="false"
              :allow-empty="true"
              :closeOnSelect="false"
            ></multiselect>
          </b-form-group>
        </b-col>
        <b-col cols="3" class>
          <b-form-group label="เลือกสี">
            <VSwatches
              v-model="selectedColor"
              :swatches="doctorColorListCode"
              row-length="5"
              swatch-size="31"
              :disabled="!Boolean(selectedRoles.find(item => item.val === 2))"
            >
              <b-input
                slot="trigger"
                :style="
                  'background-color:' + selectedColor + ';cursor: pointer'
                "
                readonly
              ></b-input>
            </VSwatches>
          </b-form-group>
        </b-col>
      </b-row>

      <b-button
        class="float-right"
        variant="success"
        :disabled="$v.selectedRoles.$invalid"
        @click="createMember"
      >
        <i class="fa fa-plus"></i> เพิ่มผู้ใช้งาน
      </b-button>
    </div>

    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";

import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

import {
  required,
  requiredIf,
  numeric,
  minLength,
  between,
  sameAs,
  not,
  helpers,
  email,
} from "vuelidate/lib/validators";
const notValue = number =>
  helpers.withParams(
    { type: "notValue", value: number },
    value => !helpers.req(value) || value != number
  );

const digits = number =>
  helpers.withParams(
    { type: "digits", value: number },
    value => !helpers.req(value) || helpers.len(value) == number
  );

const passwordFormat = helpers.regex(
  "passwordFormat",
  /^(?=.*[A-Za-z])(?=.*\d)[\u0E00-\u0E7FA-Za-z\d@$!%*#?&]{1,}$/
);

import { eventBus } from "@/main";

export default {
  name: "NewUserModal",
  components: {
    Dialog,
    Loading,
    VSwatches,
  },
  props: ["branchMembers"],
  data() {
    return {
      registerEmail: "",
      registerStatus: 0,

      // registering data
      registerForm: {
        firstNameTh: "",
        lastNameTh: "",
        firstNameEn: "",
        lastNameEn: "",
        email: "",
        mobile: "",
        password: "",
        confirmPassword: "",
        userTypeId: null,
        drLicenseNo: "",
        drSpecialItems: [],
      },
      selectedSpacialist: [],

      //registered user
      regUser: {},
      selectedRoles: [],
      selectedColor: "",

      rolesList: [],

      isLoading: false,
    };
  },
  validations: {
    registerEmail: {
      required,
      email,
      notAssigned(val) {
        return !this.isMemberAssigned(val);
      },
    },

    registerForm: {
      firstNameTh: { required },
      lastNameTh: { required },
      firstNameEn: { required },
      lastNameEn: { required },
      email: { required, email },
      mobile: { required, numeric, digits: digits(10) },
      password: {
        required,
        minLength: minLength(8),
        passwordFormat,
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
      userTypeId: { required, notValue: notValue("0") },
      drLicenseNo: {
        required: requiredIf(registerForm => {
          return registerForm.userTypeId === 2;
        }),
        numeric,
      },
      drSpecialItems: {
        required: requiredIf(registerForm => {
          return registerForm.userTypeId === 2;
        }),
      },
    },

    selectedRoles: { required },
  },
  watch: {
    selectedSpacialist(val) {
      this.registerForm.drSpecialItems = val.map(i => i.val);
    },
  },
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
      branchAuthorityList: "moduleMaster/getBranchAuthorityList",
      branchAuthorityBy: "moduleMaster/getBranchAuthorityBy",
      doctorColorList: "moduleMaster/getDoctorColorList",
      specialistList: "moduleMaster/getDrSpecialList",
    }),
    inValidEmail() {
      if (!this.$v.registerEmail.required) {
        return "กรุณาใส่อีเมลล์";
      }
      if (!this.$v.registerEmail.email) {
        return "กรุณาใส่เป็นข้อมูลเป็นรูปแบบอีเมลล์ ยกตัวอย่าง dclou@gmail.com";
      }
      if (!this.$v.registerEmail.notAssigned) {
        return "ผู้ใช้อยู่ในสาชาแล้ว";
      } else {
        return "";
      }
    },
    isDentist() {
      return this.registerForm.userTypeId === 2;
    },
    doctorColorListCode() {
      return this.doctorColorList.map(item => item.code);
    },
  },
  methods: {
    ...mapActions({
      checkBranchMember: "moduleBranch/checkBranchMember",
      createBranchMember: "moduleBranch/createBranchMember",
      registerUser: "moduleUser/registerUser",
    }),
    show() {
      this.$v.$reset();
      this.$bvModal.show("NewUserModal");
    },
    validateEmail() {
      this.$v.registerEmail.$touch();
      if (this.$v.registerEmail.$invalid) {
        this.registerStatus = 0;
        return;
      }

      //เช็คเคยลงทะเบียนหรือยัง
      this.checkBranchMember({
        email: this.registerEmail,
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      })
        .then(res => {
          if (res?.status == "200") {
            this.registerStatus = 2;
            this.assignRegisteredMember(res.data);
          } else {
            this.registerStatus = 1;
            this.registerForm.email = this.registerEmail;
          }
        })
        .catch(err => {
          this.registerStatus = 0;
          this.regUser = {};
          console.error(err);
        });
    },

    handleRegister() {
      this.regUser = {};
      this.$v.registerForm.$touch();
      if (this.$v.registerForm.$error) return;
      this.isLoading = true;
      this.registerUser(this.registerForm)
        .then(res => {
          if (res.status == 200 && res.data) {
            this.registerStatus = 2;
            this.assignRegisteredMember(res.data);
          }
        })
        .catch(err => {
          console.error(err);
          if (
            err.response.status === 400 &&
            err.response.data === "The Email is existing"
          ) {
            this.isEmailDuplicate = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    assignRegisteredMember(data) {
      this.regUser = data;
      this.rolesList = this.setRolesList();
    },
    setRolesList() {
      this.selectedRoles = [];

      // ถ้าไม่ใช่แพทย์ตัด แพทย์ออกจาก list
      if (this.regUser.userTypeId === 1) {
        return this.branchAuthorityList.filter(item => item.val != 2);
      } else {
        //เป็นแพทย์
        this.selectedRoles = [this.branchAuthorityBy(2, "val")]; //เลือก role ให้เลย
        this.autoAssignColor(); //เลือกสี
        return this.branchAuthorityList;
      }
    },
    autoAssignColor() {
      //เลือกสีเลย
      let pickedColors = this.branchMembers
        .filter(member => member.authIdItems.includes(2))
        .map(doctor => doctor.drApptColor);
      let remainColors = this.doctorColorListCode.filter(
        i => !pickedColors.includes(i)
      );
      let randomIndex = Math.floor(Math.random() * remainColors.length);
      this.selectedColor = remainColors[randomIndex];
    },
    createMember() {
      if (
        this.$v.registerEmail.$invalid ||
        this.$v.selectedRoles.$invalid ||
        !this.regUser.uid
      )
        return;
      let member = {
        uid: this.regUser.uid,
        authIdItems: this.selectedRoles.map(i => i.val),
        drApptColor: this.selectedColor,
      };
      this.isLoading = true;
      this.createBranchMember({
        member,
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      })
        .then(res => {
          if (res.status == "200") {
            this.$emit("getMember");
            this.$bvModal.hide("NewUserModal");
            eventBus.$emit("alertToast", {
              message: "เพิ่มผู้ใช้สำเร็จ",
              variant: "success",
            });
          }
        })
        .catch(err => {
          eventBus.$emit("alertToast", {
            message: "เพิ่มผู้ใช้ไม่สำเร็จ",
            variant: "warning",
          });
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    isMemberAssigned(email) {
      let found = this.branchMembers.find(
        branchMember => branchMember.user.email == email
      );
      return !!found;
    },

    setDefaultValue() {
      this.registerEmail = "";
      this.registerStatus = 0;

      this.selectedRoles = [];
      this.selectedColor = "";
      this.regUser = {};

      this.setDefaultRegisterForm();
    },
    setDefaultRegisterForm() {
      this.selectedSpacialist = [];

      this.registerForm = Object.assign({
        firstNameTh: "",
        lastNameTh: "",
        firstNameEn: "",
        lastNameEn: "",
        email: "",
        mobile: "",
        password: "",
        confirmPassword: "",
        userTypeId: null,
        drLicenseNo: "",
        drSpecialItems: [],
      });
    },
  },
  mounted() {
    // this.show();
  },
};
</script>

<style>
.normal-weight label:not(.form-check-label):not(.custom-file-label) {
  font-weight: normal;
}
</style>
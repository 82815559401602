<template>
  <div class="file-item-wrapper" :class="{ selected: isSelected }">
    <div class="img-wrap">
      <p class="small-text mb-0 text-muted mr-1">
        วันที่ Upload: {{ formatDate(item.creationDt) }}
      </p>
      <div v-if="item.isDeleting" class="d-flex justify-content-center">
        <b-spinner
          class="mt-2"
          variant="danger"
          label="Deleting..."
        ></b-spinner>
      </div>
      <div v-else class="image-container">
        <div class="hover-controls">
          <div class="checkbox-container">
            <input
              type="checkbox"
              :id="'checkbox-' + item.id"
              :checked="isSelected"
              @change="$emit('select', item)"
              class="custom-checkbox"
            />
            <label :for="'checkbox-' + item.id" class="checkbox-label"></label>
          </div>

          <b-dropdown
            size="sm"
            variant="link"
            no-caret
            right
            class=""
            @click.stop
          >
            <template #button-content>
              <div class="action-button">
                <i class="fas fa-ellipsis-v"></i>
              </div>
            </template>
            <b-dropdown-item @click.stop="$emit('download', item)">
              <i class="fas fa-download mr-2"></i> Download
            </b-dropdown-item>
            <b-dropdown-item
              v-if="item.fileType === 'application/pdf'"
              @click.stop="$emit('sign', item)"
            >
              <i class="fas fa-signature mr-2"></i> Sign
            </b-dropdown-item>
            <b-dropdown-item
              v-if="category === 'XRay'"
              @click.stop="$emit('radiiView', item)"
            >
              <i class="fas fa-x-ray mr-2"></i> RadiiView
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item
              variant="danger"
              @click.stop="$emit('delete', item)"
            >
              <i class="fas fa-trash-alt mr-2 text-danger"></i>
              <span class="text-danger">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div v-if="item.fileType === 'application/pdf'" class="text-center m-2">
          <i class="far fa-file-pdf" style="font-size: 60px"></i>
        </div>

        <b-img
          v-else
          thumbnail
          fluid
          :src="item.src"
          :alt="item.title"
          @click.stop="$emit('preview', item)"
        ></b-img>
        <p
          class="mb-0 text-center description"
          v-b-tooltip.hover.right
          :title="item.remark"
        >
          {{ truncateText(item.remark) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import truncateText from "@/utils/truncateText";
import formatDate from "@/utils/formatDate";

export default {
  name: "FileItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    category: {
      type: String,
      required: true,
    },
  },
  methods: {
    truncateText,
    formatDate,
  },
};
</script>

<style scoped>
.file-item-wrapper {
  position: relative;
  padding: 4px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.file-item-wrapper:hover {
  background-color: rgba(0, 123, 255, 0.05);
}

.file-item-wrapper.selected {
  background-color: rgba(0, 123, 255, 0.1);
}

.img-wrap {
  position: relative;
  cursor: pointer;
}

.image-container {
  position: relative;
}

.hover-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  justify-content: space-between;
  padding: 8px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
  z-index: 100;
  width: 100%;
}

.image-container:hover .hover-controls,
.file-item-wrapper.selected .hover-controls {
  display: flex;
}

.checkbox-container {
  position: relative;
  width: 24px;
  height: 24px;
}

.custom-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-label {
  position: absolute;
  top: 3px;
  left: 8px;
  height: 24px;
  width: 24px;
  background-color: white;
  border: 2px solid #dee2e6;
  border-radius: 4px;
  cursor: pointer;
  margin: 0;
}

.checkbox-label:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-checkbox:checked ~ .checkbox-label {
  background-color: #007bff;
  border-color: #007bff;
}

.custom-checkbox:checked ~ .checkbox-label:after {
  display: block;
}

.action-button {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  margin: 0;
}

.action-button:hover {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.action-button i {
  color: #495057;
}

.description {
  margin-top: 8px;
  font-size: 0.875rem;
  color: #6c757d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.small-text {
  font-size: 0.75rem;
  margin-bottom: 4px;
}
</style>

<template>
  <b-modal
    id="doctor-warning-modal"
    size="lg"
    :ok-only="true"
    :centered="true"
    ok-title="รับทราบ"
    ok-variant="warning"
    header-bg-variant="warning"
    header-text-variant="dark"
    @ok="handleOk"
  >
    <template #modal-title>
      <i class="fas fa-exclamation-triangle mr-2"></i>
      คำเตือน: ตรวจสอบข้อมูลทันตแพทย์
    </template>

    <div class="warning-content">
      <!-- Doctor Info -->
      <div class="doctor-info" v-if="doctorUser && doctorUser.fullName">
        <strong>{{ doctorUser.fullName }}</strong>
      </div>

      <!-- License Info -->
      <div
        class="mb-3 small text-muted"
        v-if="doctorUser && doctorUser.drLicenseNo"
      >
        <p class="mb-1">เลขที่ใบอนุญาต: {{ doctorUser.drLicenseNo }}</p>
      </div>

      <!-- Warning Messages -->
      <div class="warnings">
        <strong class="d-block mb-2">พบปัญหาดังต่อไปนี้:</strong>
        <div class="warning-list">
          <!-- Warning Messages -->
          <div
            v-for="invalid in invalids"
            :key="invalid"
            class="text-danger mb-2"
          >
            <i class="fas fa-times-circle mr-2"></i>
            {{ warningMessages[invalid] }}
          </div>

          <!-- Fullname Mismatch Info - Shows only once if present -->
          <div v-if="hasFullnameMismatch" class="alert alert-info p-3 mb-3">
            <i class="fas fa-info-circle mr-2"></i>
            <small>
              ในกรณีที่ชื่อไม่ตรง จะไม่มีผลต่อการเบิกจ่ายจาก สปสช. อย่างไรก็ตาม
              ขอแนะนำให้ปรับปรุงข้อมูลให้เป็นปัจจุบันเพื่อความถูกต้องและป้องกันปัญหาที่อาจเกิดขึ้นในอนาคต
            </small>
          </div>

          <!-- License Issues Section - Shows only once if any license issues exist -->
          <div v-if="hasLicenseIssues" class="mt-4 border-top pt-3">
            <div class="alert alert-warning">
              <div class="d-flex align-items-center mb-3">
                <i
                  class="fas fa-info-circle mr-2 text-warning"
                  style="font-size: 1.5rem"
                ></i>
                <h5 class="mb-0 font-weight-bold">วิธีแก้ไขปัญหา</h5>
              </div>

              <div class="">
                <p class="mb-3">กรุณาดำเนินการตามขั้นตอนด้านล่าง:</p>

                <div class="step-container mb-3">
                  <div class="d-flex align-items-start mb-3">
                    <div class="step-number mr-3">1</div>
                    <div>
                      <h6 class="font-weight-bold mb-2">
                        อัพเดทข้อมูลในโปรไฟล์ DentCloud
                      </h6>
                      <p class="text-muted mb-0">
                        ตรวจสอบและแก้ไข ชื่อ และนามสกุล
                        ในโปรไฟล์ของคุณให้ถูกต้องและเป็นปัจจุบัน
                      </p>
                    </div>
                  </div>

                  <div class="d-flex align-items-start">
                    <div class="step-number mr-3">2</div>
                    <div>
                      <h6 class="font-weight-bold mb-2">
                        อัพเดทข้อมูลในโปรไฟล์ในระบบ TDC
                      </h6>
                      <p class="text-muted mb-0">
                        หากข้อมูลใน DentCloud ถูกต้องแล้ว แต่ยังพบปัญหา
                        กรุณาแก้ไข ชื่อ และนามสกุล ในระบบ TDC เพื่อความถูกต้อง
                      </p>
                    </div>
                  </div>
                  <div class="text-center mt-3">
                    <img
                      src="https://dentcloud-public.s3.ap-southeast-1.amazonaws.com/S__42172459.jpg"
                      alt="ภาพประกอบการอบรม"
                      class="rounded shadow-sm"
                      style="max-width: 300px; width: 100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Course Issues Section - Shows only once if any course issues exist -->
          <div v-if="hasCourseIssues" class="mt-4 border-top pt-3">
            <div class="alert alert-warning mb-4">
              <div class="d-flex align-items-center mb-3">
                <i
                  class="fas fa-graduation-cap mr-3 text-warning"
                  style="font-size: 1.5rem"
                ></i>
                <h5 class="mb-0 font-weight-bold">ข้อกำหนดการอบรม</h5>
              </div>
              <div class="step-container mb-3">
                <div class="d-flex align-items-start mb-3">
                  <div class="step-number mr-3">1</div>
                  <div>
                    <h6 class="font-weight-bold mb-2">
                      ทันตแพทย์ทุกท่าน รวมถึงทันตแพทย์ Part Time
                      ที่ให้บริการในโครงการ "30 บาทรักษาทุกที่"
                      จะต้องผ่านการอบรมจากทันตแพทยสภา และได้รับ e-Certificate
                    </h6>
                  </div>
                </div>

                <div class="d-flex align-items-start">
                  <div class="step-number mr-3">2</div>
                  <div>
                    <h6 class="font-weight-bold mb-2">
                      ทันตแพทย์ Part Time สามารถเข้ารับการอบรมแบบย่อ (1 ชั่วโมง)
                    </h6>
                  </div>
                </div>
              </div>

              <!-- New Warning Message -->
              <div class="alert alert-danger mb-3">
                <div class="d-flex">
                  <i
                    class="fas fa-exclamation-triangle mr-2 text-danger"
                    style="font-size: 1.2rem"
                  ></i>
                  <div>
                    <strong class="d-block mb-1">คำเตือนสำคัญ</strong>
                    <p class="mb-0">
                      ตั้งแต่ในวันที่ 1 มกราคม พ.ศ. 2568
                      ทันตแพทย์ที่ปฏิบัติงานรักษา
                      และยังไม่ได้เข้ารับการอบรมผ่านระบบ E-Certificate
                      อาจส่งผลกระทบต่อกระบวนการเบิกจ่ายได้
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center my-4">
              <b-img
                fluid
                src="https://dentcloud-public.s3.ap-southeast-1.amazonaws.com/S__36413446.jpg"
                alt="ภาพประกอบการอบรม"
                class="max-w-full rounded shadow-sm"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Don't show today checkbox -->
      <div class="mt-4 border-top pt-3">
        <b-form-checkbox
          v-model="dontShowToday"
          class="text-muted small"
          size="sm"
        >
          ข้ามการแจ้งเตือนนี้สำหรับวันนี้
        </b-form-checkbox>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "DoctorWarningModal",
  props: {
    doctorUser: {
      type: Object,
      default: () => ({}),
    },
    invalids: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      dontShowToday: false,
      warningMessages: {
        EXPIRED_LICENSE: "ใบอนุญาตหมดอายุ",
        FULLNAME_MISMATCH: "ชื่อ-นามสกุลไม่ตรงกับใบอนุญาต",
        COURSE_NOT_ATTENDED: "ยังไม่เข้าร่วมหลักสูตร",
        NOT_FOUND: "ไม่พบเลขใบอนุญาตในระบบ",
        INACTIVE_LICENSE: "ใบอนุญาตไม่มีผลบังคับใช้",
        INVALID_EXPIRATION_DATE: "วันหมดอายุของใบอนุญาตไม่ถูกต้อง",
        COURSE_NOT_PASSED: "ยังไม่ผ่านหลักสูตร",
        CONNECTION_TIMEOUT: "การเชื่อมต่อฐานข้อมุูลขัดข้อง",
      },
      licenseRelatedIssues: [
        "EXPIRED_LICENSE",
        "FULLNAME_MISMATCH",
        "NOT_FOUND",
        "INACTIVE_LICENSE",
        "INVALID_EXPIRATION_DATE",
      ],
      courseRelatedIssues: ["COURSE_NOT_ATTENDED", "COURSE_NOT_PASSED"],
    };
  },
  computed: {
    hasFullnameMismatch() {
      return this.invalids.includes("FULLNAME_MISMATCH");
    },
    hasLicenseIssues() {
      return this.invalids.some(invalid =>
        this.licenseRelatedIssues.includes(invalid)
      );
    },
    hasCourseIssues() {
      return this.invalids.some(invalid =>
        this.courseRelatedIssues.includes(invalid)
      );
    },
  },
  methods: {
    showModal() {
      this.$bvModal.show("doctor-warning-modal");
    },
    hideModal() {
      this.$bvModal.hide("doctor-warning-modal");
    },
    handleOk() {
      if (this.dontShowToday && this.doctorUser?.uid) {
        const today = new Date().toISOString().split("T")[0];
        localStorage.setItem(
          "suppressedDoctorUid",
          JSON.stringify({
            uid: this.doctorUser.uid,
            date: today,
          })
        );
      }
    },
    isSuppressionValid(uid) {
      const stored = localStorage.getItem("suppressedDoctorUid");
      if (!stored) return false;

      const { uid: storedUid, date } = JSON.parse(stored);
      const today = new Date().toISOString().split("T")[0];

      return storedUid === uid && date === today;
    },
  },
};
</script>

<style scoped>
.step-number {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #f0ad4e;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  flex-shrink: 0;
}

.alert {
  border-radius: 8px;
  border-left: 5px solid #f0ad4e;
}

.alert-info {
  border-left-color: #17a2b8;
}

.step-container {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 6px;
  padding: 1rem;
}

h6 {
  color: #495057;
}

.text-muted {
  font-size: 0.9rem;
}

/* Add these styles to your existing scoped styles */
.course-requirements p {
  line-height: 1.6;
}

.alert-danger {
  background-color: #fff1f0;
  border-left: 4px solid #dc3545;
}

.alert-warning {
  border-left: 4px solid #f0ad4e;
}

.rounded {
  border-radius: 8px;
}

.shadow-sm {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
</style>
<template>
  <b-form @submit.prevent="submitForm" @reset.prevent="resetForm">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <div class="row">
            <div class="col-auto">
              <upload-avatar-form
                ref="UploadAvatar"
                border
                :default-id="defaultContentId"
                @callback="uploadAvatarCallback"
              ></upload-avatar-form>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-lg-6">
                  <label class="mt-1 mb-0" for="firstNameTh">ชื่อภาษาไทย</label>
                  <input
                    id="firstNameTh"
                    type="text"
                    class="form-control required"
                    :class="{ 'is-invalid': $v.profileForm.firstNameTh.$error }"
                    v-model.trim="profileForm.firstNameTh"
                    required
                  />
                  <div
                    class="description error"
                    v-if="$v.profileForm.firstNameTh.$error"
                  >
                    *กรุณาใส่ชื่อภาษาไทย
                  </div>
                </div>
                <div class="col-lg-6">
                  <label class="mt-1 mb-0" for="lastNameTh"
                    >นามสกุลภาษาไทย</label
                  >
                  <input
                    id="lastNameTh"
                    type="text"
                    class="form-control required"
                    :class="{ 'is-invalid': $v.profileForm.lastNameTh.$error }"
                    v-model.trim="profileForm.lastNameTh"
                    required
                  />
                  <div
                    class="description error"
                    v-if="$v.profileForm.lastNameTh.$error"
                  >
                    *กรุณาใส่นามสกุลภาษาไทย
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-lg-6">
                  <label class="mt-1 mb-0" for="firstNameEn"
                    >ชื่อภาษาอังกฤษ</label
                  >
                  <input
                    id="firstNameEn"
                    type="text"
                    class="form-control required"
                    :class="{ 'is-invalid': $v.profileForm.firstNameEn.$error }"
                    v-model.trim="profileForm.firstNameEn"
                    required
                  />
                  <div
                    class="description error"
                    v-if="$v.profileForm.firstNameEn.$error"
                  >
                    *กรุณาใส่ชื่อภาษาอังกฤษ
                  </div>
                </div>
                <div class="col-lg-6">
                  <label class="mt-1 mb-0" for="lastNameEn"
                    >นามสกุลภาษาอังกฤษ</label
                  >
                  <input
                    id="lastNameEn"
                    type="text"
                    class="form-control required"
                    :class="{ 'is-invalid': $v.profileForm.lastNameEn.$error }"
                    v-model.trim="profileForm.lastNameEn"
                    required
                  />
                  <div
                    class="description error"
                    v-if="$v.profileForm.lastNameEn.$error"
                  >
                    *กรุณาใส่นามสกุลภาษาอังกฤษ
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="mb-2" />
        <div class="form-group mb-4">
          <div class="row">
            <div class="col">
              <label class="mt-1 mb-0" for="email">อีเมล์</label>
              <input
                id="email"
                type="email"
                class="form-control required"
                v-model.trim="profileForm.email"
                required
                readonly
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label class="mt-1 mb-0" for="mobile">เบอร์โทรศัพท์</label>
              <input
                id="mobile"
                type="tel"
                class="form-control required"
                :class="{ 'is-invalid': $v.profileForm.mobile.$error }"
                v-model.trim="profileForm.mobile"
                required
              />
              <div
                class="description error"
                v-if="$v.profileForm.mobile.$error"
              >
                <span v-if="!$v.profileForm.mobile.required"
                  >*กรุณาใส่เบอร์โทรศัพท์</span
                >
                <span v-else-if="!$v.profileForm.mobile.numeric"
                  >*เบอร์โทรศัพท์ต้องเป็นตัวเลข</span
                >
                <span v-else-if="!$v.profileForm.mobile.digits"
                  >*เบอร์โทรศัพท์ต้องมีตัวเลข 10 ตัว</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label class="mt-1 mb-0" for="userType">ประเภทผู้ใช้งาน</label>
              <select
                id="userTypeId"
                class="form-control required"
                :class="{ 'is-invalid': $v.profileForm.userTypeId.$error }"
                v-model="profileForm.userTypeId"
                @change="changeUserType()"
                disabled
              >
                <template v-for="(val, index) in userTypeItemOptions">
                  <option v-bind:value="val.val" v-bind:key="index">
                    {{ val.label }}
                  </option>
                </template>
              </select>
              <div
                class="description error"
                v-if="$v.profileForm.userTypeId.$error"
              >
                *กรุณาเลือกประเภทผู้ใช้งาน
              </div>
            </div>
          </div>
          <template v-if="profileForm.userTypeId == '2'">
            <div class="row">
              <div class="col-12">
                <label class="mt-1 mb-0" for="drLicenseNo">เลข ท.</label>
                <input
                  id="drLicenseNo"
                  type="number"
                  class="form-control required"
                  :class="{ 'is-invalid': $v.profileForm.drLicenseNo.$error }"
                  v-model="$v.profileForm.drLicenseNo.$model"
                  required
                  number
                />
                <div
                  class="description error"
                  v-if="$v.profileForm.drLicenseNo.$error"
                >
                  *กรุณาใส่เลข ท. (ใส่เฉพาะตัวเลข)
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="mt-1 mb-0" for="selectedDrSpecialItemOptions"
                  >สาขาเฉพาะทาง</label
                >
                <multiselect
                  class="required"
                  :class="{
                    'is-invalid':
                      $v.profileForm.selectedDrSpecialItemOptions.$error,
                  }"
                  v-model="$v.profileForm.selectedDrSpecialItemOptions.$model"
                  :options="drSpecialItemOptions"
                  :multiple="true"
                  track-by="val"
                  :custom-label="customLabel"
                  :close-on-select="false"
                  required
                ></multiselect>
                <div
                  class="description error"
                  v-if="$v.profileForm.selectedDrSpecialItemOptions.$error"
                >
                  *กรุณาเลือกสาขาเฉพาะทางอย่างน้อย 1 อย่าง
                </div>
                <b-form-checkbox
                  class="mt-2"
                  v-model="profileForm.linkedWithMFD"
                  switch
                >
                  <span style="font-weight: 400">
                    เชื่อมต่อข้อมูลผู้ใช้ "MorFunDee"
                    <img
                      style="width: auto; height: 18px"
                      :src="MorFunDeeLogo"
                    />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </template>
        </div>
        <div class="row">
          <div class="col-6">
            <button type="reset" class="btn btn-danger btn-block">รีเซต</button>
          </div>
          <div class="col-6">
            <button type="submit" class="btn btn-primary btn-block">
              ยืนยัน
            </button>
          </div>
        </div>
      </div>
    </div>
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </b-form>
</template>

<script>
import { eventBus } from "@/main";
import { mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import Loading from "@/components/Loading";
import Dialog from "@/components/modal/Dialog";
import UploadAvatarForm from "@/components/form/UploadAvatar";
import MorFunDeeLogo from "../../assets/img/morfundee-logo.png";

import {
  required,
  requiredIf,
  numeric,
  minLength,
  between,
  sameAs,
  not,
  helpers,
} from "vuelidate/lib/validators";

const notValue = number =>
  helpers.withParams(
    { type: "notValue", value: number },
    value => !helpers.req(value) || value != number
  );
const digits = number =>
  helpers.withParams(
    { type: "digits", value: number },
    value => !helpers.req(value) || helpers.len(value) == number
  );

export default {
  name: "UpdateProfileForm",
  components: {
    Loading,
    Multiselect,
    Dialog,
    UploadAvatarForm,
  },
  data() {
    return {
      isLoading: false,
      profileForm: {
        contentId: "",
        firstNameTh: "",
        lastNameTh: "",
        firstNameEn: "",
        lastNameEn: "",
        email: "",
        mobile: "",
        userTypeId: 0,
        drLicenseNo: "",
        drSpecialItems: [],
        selectedDrSpecialItemOptions: [],
        linkedWithMFD: false,
      },
      MorFunDeeLogo,
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
      userTypeItemOptions: "moduleMaster/getPersonalTypeList",
      drSpecialItemOptions: "moduleMaster/getDrSpecialList",
    }),
    defaultContentId() {
      return this.userInfo ? this.userInfo.contentId : "";
    },
    mappedDrSpecial() {
      return (
        this.profileForm.selectedDrSpecialItemOptions?.map(e => e.val) || null
      );
    },
  },
  validations: {
    profileForm: {
      email: {},
      firstNameTh: { required },
      lastNameTh: { required },
      firstNameEn: { required },
      lastNameEn: { required },
      mobile: { required, numeric, digits: digits(10) },
      userTypeId: { required, notValue: notValue("") },
      drLicenseNo: {
        required: requiredIf(profileForm => {
          return profileForm.userTypeId == "2";
        }),
        numeric,
      },
      selectedDrSpecialItemOptions: {
        required: requiredIf(profileForm => {
          return profileForm.userTypeId == "2";
        }),
      },
    },
  },
  methods: {
    ...mapActions({
      setStateAvatarId: "moduleUser/setStateAvatarId",
      updateProfile: "moduleUser/updateProfile",
      fetchUserInfo: "moduleUser/fetchUserInfo",
    }),
    init() {},
    initDataComplete() {
      if (
        this.userTypeItemOptions &&
        this.drSpecialItemOptions &&
        this.userTypeItemOptions.length > 0 &&
        this.drSpecialItemOptions.length > 0
      ) {
        this.resetForm();
      }
    },
    getUserInfo() {
      this.isLoading = true;
      this.fetchUserInfo().then(res => {
        this.isLoading = false;
        if (res.status == 200) {
          eventBus.$emit("updateProfile", this.userInfo);
          this.resetForm(null, true);
        }
      });
    },
    uploadAvatarCallback(state, contentId) {
      switch (state) {
        case "uploading":
          this.isLoading = true;
          break;
        case "callback":
          this.isLoading = false;
          this.saveProfile();
          break;
        case "delete":
          this.profileForm.contentId = contentId;
          break;
        case "reset":
          this.profileForm.contentId = contentId;
          break;
        case "success":
          this.isLoading = false;
          this.profileForm.contentId = contentId;
          this.setStateAvatarId(contentId);
          this.saveProfile();
          break;
        case "fail":
          this.isLoading = false;
          this.showDialogToast("danger", `ไม่สามารถอัพโหลดรูปโปรไฟล์ได้`);
          this.saveProfile();
          break;
        default:
      }
    },
    resetForm(event, isReload) {
      if (!isReload) {
        this.$refs.UploadAvatar.reset();
      }
      this.isLoading = false;
      this.profileForm.firstNameTh = this.userInfo.firstNameTh;
      this.profileForm.lastNameTh = this.userInfo.lastNameTh;
      this.profileForm.firstNameEn = this.userInfo.firstNameEn;
      this.profileForm.lastNameEn = this.userInfo.lastNameEn;
      this.profileForm.email = this.userInfo.email;
      this.profileForm.mobile = this.userInfo.mobile;
      this.profileForm.userTypeId = this.userInfo.userTypeId;
      this.profileForm.drLicenseNo = this.userInfo.drLicenseNo;
      this.profileForm.drSpecialItems = this.userInfo.drSpecialItems;
      this.profileForm.selectedDrSpecialItemOptions = [];
      if (this.profileForm.drSpecialItems?.length > 0) {
        this.profileForm.drSpecialItems.forEach(value => {
          for (let i = 0; i < this.drSpecialItemOptions.length; i++) {
            if (this.drSpecialItemOptions[i].val == value) {
              this.profileForm.selectedDrSpecialItemOptions.push(
                this.drSpecialItemOptions[i]
              );
              break;
            }
          }
        });
      }
      this.profileForm.linkedWithMFD = this.userInfo.linkedWithMFD;
      this.$v.$reset();
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$refs.UploadAvatar.submit();
    },
    async saveProfile() {
      await this.$nextTick();
      let profileData = {
        uid: this.userInfo.uid,
        mobile: this.profileForm.mobile,
        firstNameTh: this.profileForm.firstNameTh,
        lastNameTh: this.profileForm.lastNameTh,
        firstNameEn: this.profileForm.firstNameEn,
        lastNameEn: this.profileForm.lastNameEn,
        userTypeId: this.profileForm.userTypeId,
        drLicenseNo: this.profileForm.drLicenseNo,
        drSpecialItems: this.mappedDrSpecial,
        contentId: this.profileForm.contentId,
        linkedWithMFD: this.profileForm.linkedWithMFD,
      };
      this.isLoading = true;
      this.updateProfile(profileData)
        .then(res => {
          this.isLoading = false;
          if (res.status == 200) {
            this.getUserInfo();
            this.showDialogToast("success", `อัพเดตโปรไฟล์สำเร็จ`);
            this.$root.$emit("bv::hide::modal", "UserProfileModal");
          }
        })
        .catch(res => {
          this.isLoading = false;
          this.showDialogToast("danger", `ไม่สามารถอัพเดตโปรไฟล์ได้`);
        });
    },
    customLabel(option) {
      return `${option.label}`;
    },
    selectDrSpecialItems() {
      // let selectedOptions = [];
      // this.profileForm.selectedDrSpecialItemOptions.map((e) => {
      //   e.val;
      // });
      // this.profileForm.drSpecialItems = selectedOptions;
    },
    changeUserType() {
      if (this.profileForm.userTypeId != "2") {
        this.profileForm.drLicenseNo = "";
        this.profileForm.drSpecialItems = [];
        this.profileForm.selectedDrSpecialItemOptions = [];
      }
    },
    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
  },
};
</script>

<style scoped>
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-border {
  border: none;
  color: gray;
  padding: 0;
  background-color: white;
  border-radius: 0;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
</style>
<template>
  <b-card v-show="extendedProps.nhsoVisitNo > 0" bg-variant="sky">
    <b-card-title
      >คำแนะนำการบันทึกการรักษา "30 บาทรักษาทุกที่"
      <img
        class="mr-1"
        style="width: auto; height: 32px"
        src="@/assets/img/NHSO_logo.png"
    /></b-card-title>
    <b-card-text>
      <h6 v-if="extendedProps && extendedProps.nhsoVisitNo" class="">
        <div class="alert alert-warning" role="alert">
          <strong>รับการรักษา Visit ที่ {{ extendedProps.nhsoVisitNo }}</strong>
        </div>
      </h6>
      <div>
        <h6>Visit ที่ 1</h6>
        <p>
          1. สร้างแผนการรักษา <br />
          2. ประเมินความเสียงฟันผุ <br />
          3. บันทึกรายการ 2330011 - Comprehensive oral evaluation
          <br />
          4. บันทึกรายการที่ได้รักษา ICD10 ตาม Cheif Complaint <br />
          5. ใส่ Cheif Complaint ในช่องโน๊ต <br />
        </p>
      </div>
      <div>
        <h6>Visit ที่ 2-3</h6>
        <p>
          1. รักษา และบันทึกข้อมูล ICD10 ตามแผนการรักษา <br />
          2. ใส่ Cheif Complaint ถ้าไม่มีใส่ "-" ในช่อง note <br />
          3. สามารถใส่หมายเหตุเพิ่มเติม
          ในกรณีที่มีการแปลี่ยนแผนการรักษาในช่องโน๊ต
        </p>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: "NHSOAnnouceCard",
  props: ["extendedProps"],
  data() {
    return {};
  },
  created() {},
  watch: {},
  computed: {},
  methods: {},
  mounted() {},
  beforeDestroy() {},
};
</script>

<style>
</style>